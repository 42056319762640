import { Link } from 'gatsby';
import * as React from 'react';

const AboutPage = () => {
  return (
    <main>
      <h1>about</h1>
      <Link to="/">메인</Link>
    </main>
  );
};

export default AboutPage;
